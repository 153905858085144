body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body.no-scroll {
  overflow-y: hidden;
}

html {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

:root {
  scrollbar-gutter: stable;
}

#root {
  overflow: hidden;
}

b {
  font-weight: 700;
}

ul.social-links {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  column-gap: 20px;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-32 {
  margin-bottom: 32px;
}

.mb-42 {
  margin-bottom: 42px;
}

.mb-56 {
  margin-bottom: 56px;
}

.mr-8 {
  margin-right: 8px;
}

.mr-24 {
  margin-right: 24px;
}

.mr-32 {
  margin-right: 32px;
}

.mr-42 {
  margin-right: 42px;
}

.mr-56 {
  margin-right: 56px;
}

.ml-8 {
  margin-left: 8px;
}

.ml-24 {
  margin-left: 24px;
}

.ml-32 {
  margin-left: 32px;
}

.ml-42 {
  margin-left: 42px;
}

.ml-56 {
  margin-left: 56px;
}

@font-face {
  font-family: "Roboto Condensed";
  font-weight: 700;
  src: local("Roboto Condensed Bold"), url("../fonts/RobotoCondensed-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Condensed";
  font-weight: 400;
  src: local("Roboto Condensed Light"), url("../fonts/RobotoCondensed-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  src: local("Montserrat"), url("../fonts/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-weight: 500;
  src: local("Roboto"), url("../fonts/Roboto-Regular.ttf") format("truetype");
}